<template>
  <div>
    <div class="banner" id="hospital_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img :src="banner" :alt="$t('indexnav.Our Medical Institutions')" /> -->
      <span
        >{{ $t("indexnav.Our Medical Institutions")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Our Medical Institutions</i
        ></span
      >
    </div>
    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> > {{ $t("hospitals.title") }} -->
      <bread-crumb />
    </section>

    <figure>
      <figcaption>
        <!-- 自有医院 -->
        {{ $t("hospitals.Self-owned") }}
      </figcaption>
      <div>
        <!-- loading -->
        <div class="loading_box" v-if="showLoading"></div>
        <a
          :href="item.jumpUrl"
          target="_blank"
          v-for="item in zyyy"
          :key="item.id"
          style="padding-left: 21px"
        >
          <div class="img_box">
            <img :src="item.img" :alt="item.name" />
            <!-- <span>{{item.name}}</span> -->
          </div>
          <span>{{ filterLang(item, "Name") }}</span>
        </a>
      </div>
    </figure>
    <figure>
      <figcaption>
        <!--  共同管理 -->
        {{ $t("hospitals.Jointly-managed") }}
      </figcaption>
      <div>
        <!-- 成都锦欣医院 -->
        <!-- loading -->
        <div class="loading_box" v-if="showLoading"></div>
        <a
          style="margin-left: 21px"
          :href="i.jumpUrl"
          target="_blank"
          v-for="i in gtgl"
          :key="i.id"
        >
          <div class="img_box">
            <img :src="i.img" alt="" />
          </div>
          <span>{{ filterLang(i, "Name") }}</span>
        </a>
      </div>
    </figure>
  </div>
</template>

<script>
import { HospitalList } from "@/api/Qixiahospitals";
import { filterLang } from "@/utils/LangChange";
import { getAction } from "@/api/manage";
import { PageType } from "@/utils/constant";

export default {
  data() {
    return {
      dataList: [],
      zyyy: [], //自有医院
      gtgl: [], //共同管理
      showLoading: true,
      banner: "",
    };
  },
  methods: {
    filterLang,
     //排序
    sortby(prop, rev = true) {
      // prop 属性名
      // rev  升序降序 默认升序
      return function (a, b) {
        var val1 = a[prop];
        var val2 = b[prop];
        return rev ? val1 - val2 : val2 - val1;
      };
    },
  },
  created() {
    // 获取旗下医院banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.QIXIAYIYUAN,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
        if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });

    HospitalList().then((res) => {
      this.showLoading = false;
      console.log(res);
      this.dataList = res.result;
      // 转换 自有医院 ,共同管理
      res.result.map((res) => {
        console.log(res);
        if (res.type == "zyyy") {
          this.zyyy.push(res);
        }
        if (res.type == "gtgl") {
          this.gtgl.push(res);
        }
      });
   // 排序
      this.zyyy.sort(this.sortby("sort"));
      this.gtgl.sort(this.sortby("sort"));
      console.log("排序",this.zyyy, this.gtgl);
    });
  },
};
</script>

<style scoped>
.banner {
  position: relative;
}
header + a img {
  max-width: 100%;
  width: 100%;
}

.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

figure {
  margin: 2em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

figure figcaption {
  background: #165da3;
  font-size: 1.2em;
  color: #fff;
  font-weight: 500;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}

figure>div {
  background: #eeeeee;
  padding: 0.6em 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

figure div a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

figure div a:nth-child(1),
figure div a:nth-child(4),
figure div a:nth-child(7) {
  /* margin-left: 2%; */
}

figure div a:nth-child(3),
figure div a:nth-child(6),
figure div a:nth-child(9) {
  /* margin-right: 2%; */
}
.img_box{
  padding: 0.6em 0;
  width: 374px;
  height: 240px;
    padding: 0.6em 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
figure div a div img {
  width: 100%;
  height: 100%;
  /* object-fit: cover */
}

figure div a span {
  font-size: 1.2em;
  line-height: 2em;
  border-bottom: 2px solid #c81a41;
  padding-bottom: 0.4em;
  margin-bottom: 0.4em;
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }

  figure {
    padding: 0 1.5em 1.5em 1.5em;
  }

  figure div a span {
    font-size: 1em;
  }
}

@media screen and (max-width: 768px) {
  figure div {
    display: flex;
    flex-direction: column;
  }

  figure div a {
    width: 100%;
  }

  figure div a div img {
    width: 98%;
  }

  figure div a:nth-child(1),
  figure div a:nth-child(2),
  figure div a:nth-child(3),
  figure div a:nth-child(4),
  figure div a:nth-child(5),
  figure div a:nth-child(6),
  figure div a:nth-child(7),
  figure div a:nth-child(8) {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
